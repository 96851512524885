<template>
    <div class="w-full">
        <p class="mb-4 mt-4">Features</p>

        <div class="grid grid-cols-2 gap-6 p-4 bg-gray-100 rounded-lg shadow-md">
            <div v-for="(feature, index) in features" :key="index"
                 class="flex items-center justify-between p-3 bg-white rounded-lg shadow-sm hover:bg-gray-50 transition">
                <div class="flex items-center space-x-2">
                    <span class="font-semibold text-gray-700">{{ feature.display_name }}</span>
                </div>
                <div v-if="feature.type === 'bool'">
                    <input type="checkbox" v-model="selectedFeatures[feature.id]"
                           @change="updateFeature(feature.id, selectedFeatures[feature.id])"
                           class="form-checkbox h-6 w-6 text-blue-600 rounded focus:ring-blue-500">
                </div>
                <div v-else>
                    <input type="text" v-model="selectedFeatures[feature.id]"
                           @input="updateFeature(feature.id, selectedFeatures[feature.id])"
                           class="form-input w-full py-1 px-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                           placeholder="Enter value">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios";

export default {
    props: {
        initialFeatures: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            features: [],
            selectedFeatures: {}
        };
    },
    methods: {
        async fetchFeatures() {
            try {
                const response = await VisaApi.get('/features');

                if (response.data) {
                    this.features = response.data;
                    this.features.forEach(feature => {
                        const initialFeature = this.initialFeatures.find(initFeature => initFeature.id == feature.id);
                        if (feature.type === 'int') {
                            this.selectedFeatures[feature.id] = initialFeature ? initialFeature.value : 0;
                        } else {
                            this.selectedFeatures[feature.id] = initialFeature ? initialFeature.value : false;
                        }
                    });
                }
            } catch (error) {
                console.error('Error fetching features:', error);
            }
        },
        updateFeature(id, value) {
            this.$emit('update-features', {id, value});
        }
    },
    mounted() {
        this.fetchFeatures();
    }
};
</script>

<style scoped>
.form-checkbox {
    cursor: pointer;
}

.form-input {
    min-width: 120px;
    padding: 0.5rem;
}
</style>
