<template>
    <div class="px-4 py-2">
        <div class="flex flex-wrap items-center justify-between pb-2">
            <Breadcrumb :text="breadcrumb"/>
            <router-link to="/list-subscription-plan">
                <button class="back-btn" type="button">
                    <back-icon class="mt-1 mr-2"/>
                    Back to list
                </button>
            </router-link>
        </div>
        <div class="visasis-card">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <!-- Plan Name -->
                <div class="w-full">
                    <label class="flex items-center">Name<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.name"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Plan Name"
                        type="text"
                    />
                    <span v-if="form.errors().has('name')" class="text-red-800 text-xs">
            {{ form.errors().get('name') }}
          </span>
                </div>

                <!-- Description -->
                <div class="w-full">
                    <label class="flex items-center">Description</label>
                    <textarea
                        v-model="form.description"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Description"
                    ></textarea>
                    <span v-if="form.errors().has('description')" class="text-red-800 text-xs">
            {{ form.errors().get('description') }}
          </span>
                </div>

                <!-- Is Active -->
                <div class="w-full">
                    <label class="flex items-center">Is Active</label>
                    <div class="flex items-center space-x-4">
                        <label class="inline-flex items-center space-x-2">
                            <input
                                v-model="form.is_active"
                                type="radio"
                                value="1"
                                class="text-blue-600 form-radio focus:ring focus:ring-offset-2 focus:ring-blue-500"
                            />
                            <span>Active</span>
                        </label>
                        <label class="inline-flex items-center space-x-2">
                            <input
                                v-model="form.is_active"
                                type="radio"
                                value="0"
                                class="text-blue-600 form-radio focus:ring focus:ring-offset-2 focus:ring-blue-500"
                            />
                            <span>Inactive</span>
                        </label>
                    </div>
                </div>

                <!-- Is Enterprise -->
                <div class="w-full">
                    <label class="flex items-center">Is Enterprise</label>
                    <div class="flex items-center space-x-4">
                        <label class="inline-flex items-center space-x-2">
                            <input
                                v-model="form.is_enterprise"
                                type="radio"
                                value="1"
                                class="text-blue-600 form-radio focus:ring focus:ring-offset-2 focus:ring-blue-500"
                            />
                            <span>Enterprise</span>
                        </label>
                        <label class="inline-flex items-center space-x-2">
                            <input
                                v-model="form.is_enterprise"
                                type="radio"
                                value="0"
                                class="text-blue-600 form-radio focus:ring focus:ring-offset-2 focus:ring-blue-500"
                            />
                            <span>Common</span>
                        </label>
                    </div>
                </div>

                <!-- Yearly Price -->
                <div class="w-full">
                    <label class="flex items-center">Yearly Price<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.yearly_price"
                        type="number"
                        step="0.01"
                        min="0"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Yearly Price"
                    />
                    <span v-if="form.errors().has('yearly_price')" class="text-red-800 text-xs">
            {{ form.errors().get('yearly_price') }}
          </span>
                </div>

                <!-- Monthly Price -->
                <div class="w-full">
                    <label class="flex items-center">Monthly Price<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.monthly_price"
                        type="number"
                        step="0.01"
                        min="0"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Monthly Price"
                    />
                    <span v-if="form.errors().has('monthly_price')" class="text-red-800 text-xs">
            {{ form.errors().get('monthly_price') }}
          </span>
                </div>

                <!-- Seven Day Price -->
                <div class="w-full">
                    <label class="flex items-center">Seven Day Price<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.seven_day_price"
                        type="number"
                        step="0.01"
                        min="0"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Seven Day Price"
                    />
                    <span v-if="form.errors().has('seven_day_price')" class="text-red-800 text-xs">
            {{ form.errors().get('seven_day_price') }}
          </span>
                </div>

                <!-- Currency -->
                <div class="w-full">
                    <label class="flex items-center">Currency<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.currency"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Currency"
                        type="text"
                        maxlength="3"
                    />
                    <span v-if="form.errors().has('currency')" class="text-red-800 text-xs">
            {{ form.errors().get('currency') }}
          </span>
                </div>

                <!-- Invoice Period -->
                <div class="w-full">
                    <label class="flex items-center">Invoice Period<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.invoice_period"
                        type="number"
                        step="1"
                        min="0"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Invoice Period"
                    />
                    <span v-if="form.errors().has('invoice_period')" class="text-red-800 text-xs">
        {{ form.errors().get('invoice_period') }}
    </span>
                </div>

                <!-- Invoice Interval -->
                <div class="w-full">
                    <label class="flex items-center">Invoice Interval<span class="text-red-500 ml-1">*</span></label>
                    <select
                        v-model="form.invoice_interval"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                    >
                        <option disabled selected>Please Select</option>
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                    </select>
                    <span v-if="form.errors().has('invoice_interval')" class="text-red-800 text-xs">
        {{ form.errors().get('invoice_interval') }}
    </span>
                </div>


                <!-- Trial Period -->
                <div class="w-full">
                    <label class="flex items-center">Trial Period<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.trial_period"
                        type="number"
                        step="1"
                        min="0"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Trial Period"
                    />
                    <span v-if="form.errors().has('trial_period')" class="text-red-800 text-xs">
            {{ form.errors().get('trial_period') }}
          </span>
                </div>

                <!-- Trial Interval -->
                <div class="w-full">
                    <label class="flex items-center">Trial Interval<span class="text-red-500 ml-1">*</span></label>
                    <select
                        v-model="form.trial_interval"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                    >
                        <option disabled selected>Please Select</option>
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                    </select>
                    <span v-if="form.errors().has('trial_interval')" class="text-red-800 text-xs">
                        {{ form.errors().get('trial_interval') }}
                    </span>
                </div>

                <!-- Grace Period -->
                <div class="w-full">
                    <label class="flex items-center">Grace Period<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.grace_period"
                        type="number"
                        step="1"
                        min="0"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Grace Period"
                    />
                    <span v-if="form.errors().has('grace_period')" class="text-red-800 text-xs">
            {{ form.errors().get('grace_period') }}
          </span>
                </div>

                <!-- Grace Interval -->
                <div class="w-full">
                    <label class="flex items-center">Grace Interval<span class="text-red-500 ml-1">*</span></label>
                    <select
                        v-model="form.grace_interval"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                    >
                        <option disabled selected>Please Select</option>
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="year">Year</option>
                    </select>
                    <span v-if="form.errors().has('grace_interval')" class="text-red-800 text-xs">
            {{ form.errors().get('grace_interval') }}
          </span>
                </div>

                <!-- Sort Order -->
                <div class="w-full">
                    <label class="flex items-center">Sort Order<span class="text-red-500 ml-1">*</span></label>
                    <input
                        v-model="form.sort_order"
                        type="number"
                        step="1"
                        min="0"
                        class="py-2 rounded border border-gray-300 bg-gray-50 text-sm placeholder-gray-400 focus:border-invblue w-full px-4 mt-1"
                        placeholder="Sort Order"
                    />
                    <span v-if="form.errors().has('sort_order')" class="text-red-800 text-xs">
            {{ form.errors().get('sort_order') }}
          </span>
                </div>
                <br>
                <!-- Features -->
                <div class="w-[70vw] mt-4" v-if="form.features.length > 0">
                    <hr>
                    <FeatureListCreate :initialFeatures="form.features" @update-features="updateFeatures"/>
                </div>
            </div>

            <!-- Submit Button -->
            <div class=" mt-4">
                <button
                    v-if="!isSubmitting"
                    @click="createPlan"
                    class="mt-4 justify-center rounded-md bg-blue-600 px-6 py-1.5 text-white hover:bg-blue-500"
                >
                    Submit
                </button>
                <span v-else class="block">
          <LoadingButton text="Submitting..."/>
        </span>
            </div>
        </div>
    </div>
</template>

<script>
import VisaApi from "@/libs/axios";
import form from "vuejs-form";
import FeatureListCreate from "@/component/FeatureListCreate.vue";
import LoadingButton from "@/component/LoadingButton.vue";
import Breadcrumb from "../../../component/Breadcrumb.vue";
import BackIcon from "../../../data/icons/BackIcon.vue";

export default {
    components: {BackIcon, Breadcrumb, FeatureListCreate, LoadingButton},
    data() {
        return {
            breadcrumb: '',
            isSubmitting: false,
            form: form.default({
                id: "",
                name: "",
                description: "",
                is_active: 0,
                is_enterprise: 0,
                yearly_price: 0,
                monthly_price: 0,
                seven_day_price: 0,
                currency: "",
                trial_period: 0,
                trial_interval: "",
                invoice_period: 0,
                invoice_interval: "",
                grace_period: 0,
                grace_interval: "",
                sort_order: 0,
                features: [],
            }).rules({
                name: "required",
                yearly_price: "required",
                monthly_price: "required",
                seven_day_price: "required",
                currency: "required|string",
                trial_period: "required|integer",
                trial_interval: "required|string",
                grace_period: "required|integer",
                grace_interval: "required|string",
                sort_order: "required|integer",
                features: "required|array",
            }),
        };
    },
    methods: {
        updateFeatures({id, value}) {
            const feature = {id: id.toString(), value};
            const index = this.form.features.findIndex(f => f.id === id.toString());

            if (index !== -1) {
                this.form.features[index] = feature;
            } else {
                this.form.features.push(feature);
            }
        },
        createPlan() {
            if (this.form.validate().errors().any()) {
                return;
            }
            console.log(JSON.parse(JSON.stringify(this.form.all())))
            this.isSubmitting = true;
            VisaApi.patch("/subscription-plans/" + this.form.id, this.form.all())
                .then((response) => {
                    this.$toast.success("Plan created successfully", {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.$router.push({name: "list-subscription-plan"});
                    this.isSubmitting = false;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.message, {
                        position: "top-right",
                        dismissible: true,
                    });
                    this.isSubmitting = false;
                });
        },
        async getSingleData(id) {
            try {
                const response = await VisaApi.get(`/subscription-plans/${id}`);
                if (response.code === 200) {
                    this.form.id = response.data.id;
                    this.form.name = response.data.name;
                    this.form.description = response.data.description;
                    this.form.is_active = response.data.is_active;
                    this.form.is_enterprise = response.data.is_enterprise;
                    this.form.yearly_price = response.data.yearly_price;
                    this.form.monthly_price = response.data.monthly_price;
                    this.form.seven_day_price = response.data.seven_day_price;
                    this.form.currency = response.data.currency;
                    this.form.trial_period = response.data.trial_period;
                    this.form.trial_interval = response.data.trial_interval;
                    this.form.invoice_period = response.data.invoice_period;
                    this.form.invoice_interval = response.data.invoice_interval;
                    this.form.grace_period = response.data.grace_period;
                    this.form.grace_interval = response.data.grace_interval;
                    this.form.sort_order = response.data.sort_order;
                    this.form.features = response.data.features.map(feature => ({
                        id: feature.pivot.feature_id.toString(),
                        value: feature.pivot.value
                    }));
                }
            } catch (error) {
                console.error("Error fetching data", error);
            }
        },
    },
    mounted() {
        this.breadcrumb = this.$router.currentRoute.value.meta.breadcrumb;
        const id = this.$route.params.id;
        this.getSingleData(id);
    }
};
</script>

<style scoped>
.visasis-card {
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
</style>
